<template>
  <div id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
    <div class="col content">
      <b-form class="p-4" ref="resetPassword-form" @submit="passwordReset">
        <h5><strong>Settings</strong></h5>
          <div class="row form-group mb-0">
            <div class="col-lg-12 mt-2">
              <label class="text-secondary"><strong>Receive alerts &amp; notifications from Hireclap</strong></label>
              <div class="row">
                <div class="col-xs-4 ml-3 mr-3">
                  <label for="disable" style="padding-top:5px;">Disable</label>
                </div>
                <div class="col-xs-4 mr-3">
                  <v-switch class="mt-0" v-model="user.alert_enabled" @change="updateNotification"></v-switch>
                </div>
                <div class="col-xs-4">
                  <label for="enable" style="padding-top:5px;">Enable</label>
                </div>
              </div>
            </div>  
          </div>
          <div class="row form-group">
            <div class="col-lg-12 mt-2">
              <label class="text-secondary"><strong>Change password</strong> </label><br>
                <div class="error_msg" v-if="(typeof validate.response.matching != 'undefined' && !validate.response.matching.val)">
                  {{ validate.response.matching.msg }}
                </div>
                <div class="error_msg" v-if="this.error_msg">{{ error_msg }}</div>
                <div class="success_msg" v-if="success_msg">{{ success_msg }}</div>
                <label>Current Password<span style="color: red">*</span></label>
                <div class="input-group mb-2 mr-sm-2">
                <input 
                  type="password"
                  id="old_password"
                  ref="old_password"
                  placeholder="Current Password"
                  class="form-control fields"
                  :class="{
                  'is-invalid':
                    typeof validate.response.old_password != 'undefined' &&
                    !validate.response.old_password.val,
                }"
                  name="old_password"
                  style="border-right: none;"
                  v-model="reset.old_password"
                  @input="validate.passwordValidation(reset.old_password, 'old_password')"
                />
                <div class="input-group-prepend">
                     <div class="input-group-text pwd">
                        <i toggle="#password-field"  :class="{'mdi-eye-off': this.isText.old_password, 'mdi-eye': this.isPassword.old_password}" class="mdi menu-icon  toggle-password" @click="togglePassword('old_password')"></i>
                    </div>
                </div> 
                <div
                  class="invalid-feedback"
                    v-if="
                      typeof validate.response.old_password != 'undefined' &&
                      !validate.response.old_password.val
                    "
                  >
                    {{ validate.response.old_password.msg }}
                  </div>
                  </div>                  
            </div>
                </div>
                 <div class="row form-group">
                  <div class="col-lg-12">
                  <label>New Password<span style="color: red">*</span></label>
                <div class="input-group mb-2 mr-sm-2">
                <input 
                  type="password"
                  id="new_password"
                  ref="new_password"
                  placeholder="New Password"
                  class="form-control fields"
                  :class="{
                  'is-invalid':
                    typeof validate.response.new_password != 'undefined' &&
                    !validate.response.new_password.val,
                }"
                  name="new_password"
                  style="border-right: none;"
                  v-model="reset.new_password"
                  required=""
                  @input="validate.passwordValidation(reset.new_password, 'new_password')"
                />
                <div class="input-group-prepend">
                     <div class="input-group-text pwd">
                        <i toggle="#password-field" :class="{'mdi-eye-off': this.isText.new_password, 'mdi-eye': this.isPassword.new_password}" class="mdi menu-icon toggle-password" @click="togglePassword('new_password')"></i>
                    </div>
                </div>
                
                <div
                  class="invalid-feedback"
                    v-if="
                      typeof validate.response.new_password != 'undefined' &&
                      !validate.response.new_password.val
                    "
                  >
                    {{ validate.response.new_password.msg }}
                  </div>
                   </div> 
            </div>
                </div>
                 <div class="row form-group">
                  <div class="col-lg-12">
                  <label>Confirm Password<span style="color: red">*</span></label>
                <div class="input-group mb-2 mr-sm-2">
                <input 
                  type="password"
                  id="confirm_password"
                  ref="confirm_password"
                  placeholder="Corfirm Password"
                  class="form-control fields"
                  :class="{
                  'is-invalid':
                    typeof validate.response.confirm_password != 'undefined' &&
                    !validate.response.confirm_password.val,
                }"
                  name="confirm_password"
                  style="border-right: none;"
                  v-model="confirm_password"
                  required=""
                  @input="validate.passwordValidation(confirm_password, 'confirm_password');
                  validate.matchPassword(reset.new_password, confirm_password)"
                />
                <div class="input-group-prepend">
                     <div class="input-group-text pwd">
                        <i toggle="#password-field" :class="{'mdi-eye-off': this.isText.confirm_password, 'mdi-eye': this.isPassword.confirm_password}" class="mdi menu-icon toggle-password" @click="togglePassword('confirm_password')"></i>
                    </div>
                </div>
                <div
                  class="invalid-feedback"
                    v-if="
                      typeof validate.response.confirm_password != 'undefined' &&
                      !validate.response.confirm_password.val
                    "
                  >
                    {{ validate.response.confirm_password.msg }}
                  </div>
                  </div>
            </div>
                </div>
                <div class="row form-group">
                  <div class="col-lg-12">
                  <button class="btn btn-outline-primary" type="submit">Change Password</button>
                </div>             
                </div>
          </b-form>
        </div>
      </div>
</template>

<script>
import validation from '../libraries/validFormat' ;
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
    name:'Settings',
    props: ['user'],
    computed:{
    },
    data() {
      return{
        action: new Actions(),
        validate: new validation(),
        isPassword: {
          old_password: true,
          confirm_password: true,
          new_password: true
        },
        isText: {
          old_password: false,
          confirm_password: false,
          new_password: false
        },
        reset: {
          old_password: '',
          new_password:''
        },
        confirm_password: '',
        can_submit: 0,
        success_msg: '',
        error_msg: ''
      }
    },
    methods: {
      updateNotification: function () {
        let token = this.$cookies.get("hire-seeker").token;
        let status = this.user.alert_enabled  ? 1 : 0;
        this.action.updateAlertSettings(status, token).then((res) => {
            if(res.status) {
              this.success_msg = res.message
            }
           }).catch((err) => {
            if(err){
              console.log(err);
            }
          });
      },
      togglePassword: function(id) {
        let password = this.$refs[id];
        if (password.getAttribute('type') === 'password') {
          password.setAttribute('type', 'text');
          this.isPassword[id] = false;
          this.isText[id] = true;
        }
        else{
           password.setAttribute('type', 'password');
            this.isPassword[id] = true;
            this.isText[id] = false;
        }
      },
      passwordReset: function(e) {
        e.preventDefault();
        this.can_submit = 0;
        this.error_msg = '';
        this.success_msg = '';

        if (this.validate.passwordValidation(this.reset.old_password, "old_password")?.val === false) {
          this.can_submit++;
        }
        if (this.validate.passwordValidation(this.reset.new_password, "new_password")?.val === false) {
          this.can_submit++;
        }
        if (this.validate.matchPassword(this.reset.new_password, this.confirm_password)?.val === false) {
          this.can_submit++;
        }
        if (this.can_submit === 0) {
          let token = this.$cookies.get("hire-seeker").token;
          let data =  {
            input : this.reset,
            token: token
          };
          this.action.resetPassword(data).then((res) => {
            if(res.status) {
              this.success_msg = res.message
              this.reset.old_password = '',
              this.reset.new_password = '',
              this.confirm_password = ''
            } else {
              this.error_msg = res.error
            }
           }).catch((err) => {
            if(err){
              console.log(err);
              this.error_msg = err.error;
            }
          });
        }
      }
    }
    
};
</script>